body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

body {
  background-color: white; }

.ConsoleAnimation {
  min-width: 175px;
  min-height: 136px; }

.Intro {
  border-bottom: 3px solid white;
  background-position: bottom;
  background-size: cover; }

.intro-overlay {
  background: linear-gradient(to right, rgba(119, 161, 211, 0.9), rgba(121, 203, 202, 0.9), rgba(230, 132, 174, 0.9)); }

.introduction-text-container,
.introduction-image-container {
  margin-top: 3rem; }

.introduction-text-card,
.introduction-image-card {
  min-height: 500px;
  border-radius: 10px;
  background-color: #333333;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.5), 0 15px 12px rgba(0, 0, 0, 0.42); }

.introduction-heading {
  background-color: #222222;
  border-radius: 6px;
  padding: 0.5rem;
  left: -10%;
  top: 10%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.intruduction-animation-1 {
  background-color: #222222;
  border-radius: 6px;
  padding: 0.5rem;
  right: -10%;
  top: 35%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.intruduction-animation-2 {
  background-color: #222222;
  border-radius: 6px;
  padding: 0.5rem;
  left: -10%;
  bottom: 10%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.introduction-image {
  border: 2px solid #333333;
  border-radius: 6px;
  padding: 0.5rem;
  top: 10%;
  right: -10%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.bg-lightblue {
  background-color: #BDBDBD; }

.bg-grey {
  background-color: #333333; }

.bg-blue {
  background-color: #303F9F; }

.bg-hazel {
  background: #77a1d3;
  background: linear-gradient(to right, #77A1D3, #79CBCA, #E684AE); }

.text-hazelblue {
  color: #77A1D3; }

.text-hazelgreen {
  color: #79CBCA; }

.text-hazelred {
  color: #E684AE; }

.text-darkblue {
  color: #303F9F; }

.text-blue {
  color: #2196F3; }

.text-lightblue {
  color: #BBDEFB; }

.text-green {
  color: #4CAF50; }

.text-lightgreen {
  color: #8BC34A; }

.text-amber {
  color: #FFC107; }

.text-red {
  color: #F44336; }

.Project {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

.cursor {
  width: 10px;
  height: 100%;
  display: inline-block;
  border-bottom: 3px solid white; }

.icon {
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-radius: 6px;
  width: 5rem;
  height: 5rem; }

.fs-2 {
  font-size: 1.3rem; }

